import {
    SimpleLogoContainer,
    SimpleNavLink,
    SimpleNav,
    Logo
} from "./navigation.styles";

import './navbar.scss';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import { Link } from "@mui/material";

const SimpleNavBar = ({ isVisible }) => {
    return (
        <SimpleNav className={`${isVisible ? 'visible' : 'hidden'}`}>
            <SimpleLogoContainer className="logocontainer" to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}><Logo src="logo_manambato.png" alt="le sable blanc lodge manambato logo" /></SimpleLogoContainer>
            <div className="links">
                <SimpleNavLink className='nav-item' to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    HOME
                </SimpleNavLink>
                <SimpleNavLink className='nav-item' to="/services" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    SERVICES
                </SimpleNavLink>
                <SimpleNavLink className='nav-item' to="/contact" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    CONTACT US
                </SimpleNavLink>
            </div>
            <div className="otherlinks" style={{margin: '20px'}}>
                <Link href='https://web.facebook.com/people/Le-sable-Blanc-Lodge/61556933793681/' sx={{ color: '#000' }} target="_blank"><FacebookIcon /></Link>
                <Link href='https://www.instagram.com/lesableblanc89/' sx={{ color: '#000' }} target="_blank"><InstagramIcon /></Link>
            </div>
        </SimpleNav>
    )
}

export default SimpleNavBar;
import { InfoContainer, LogoContainer, InfoText } from "./infobar.styles";
import { Link } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
const InfoBar = () => {
    return (
        <InfoContainer>
            <InfoText>Call us: +261 38 24 012 98 | <a href="mailto:lesableblancantandrokomby@gmail.com" style={{ textDecoration: 'none', color: 'black', fontWeight: 'normal'}}>lesableblancantandrokomby@gmail.com</a></InfoText>
            <LogoContainer>
                <Link href='https://web.facebook.com/people/Le-sable-Blanc-Lodge/61556933793681/' sx={{ color: '#000' }} target="_blank"><FacebookIcon /></Link>
                <Link href='https://www.instagram.com/lesableblanc89/' sx={{ color: '#000' }} target="_blank"><InstagramIcon /></Link>
            </LogoContainer>
        </InfoContainer>
    )
}

export default InfoBar;
import './home.styles.scss';
import CarouselComponent from '../../components/carousel/carousel.component';
import Box from '../../components/box/box.component';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Viewer from 'react-viewer';
import React from 'react';

const itemData = [
    {
        src: 'images/activities/sable_blanc_132.jpg',
        alt: 'Boating',
    },
    {
        src: 'images/activities/sable_blanc_91.jpg',
        alt: 'Hiking',
    },
    {
        src: 'images/activities/sable_blanc_93.jpg',
        alt: 'Fishing',
    },
    {
        src: 'images/activities/sable_blanc_133.jpg',
        alt: 'Swimming',
    }
];
const Home = () => {
    const isExtraLargeScreen = useMediaQuery('(min-width:1500px)');
    const cols = useMediaQuery('(max-width:800px)') ? 1 : 2;

    const linkStyle = {
        display: 'flex',
        justifyContent: 'center',
        width: 'fit-content',
        margin: 'auto',
        color: '#D0AC01',
        border: 'solid #D0AC01',
        paddingLeft: isExtraLargeScreen ? '40px' : '20px',
        paddingRight: isExtraLargeScreen ? '40px' : '20px',
        paddingTop: isExtraLargeScreen ? '10px' : '5px',
        paddingBottom: isExtraLargeScreen ? '10px' : '5px',
        gap: '10px',
        ':hover': {
            backgroundColor: '#D0AC01',
            color: '#fff',
            fontWeight: 'bold',
        },
        '@media (max-width: 930px)': {
            backgroundColor: '#FFF'
        }
    };

    const [activeIndex, setActiveIndex] = React.useState(0);
    const [visible, setVisible] = React.useState(false);

    return (
        <div className='home'>
            <Box>
                <div className='welcome'>
                    <h2 style={{ textAlign: 'center' }}>WELCOME TO</h2>
                    <p className='title'>Le Sable Blanc <br />Lodge ANTANDROKOMBY</p>
                    <p className='address'>Manambato - MADAGASCAR</p>
                </div>
            </Box>
            <Box>
                <div className='about-us'>
                    <img src="images/sable_blanc_6.jpg" alt="sable_blanc" className='image' />
                    <div className='description'>
                        <h2>ABOUT US</h2>
                        <p className='text'>&nbsp;&nbsp;&nbsp;&nbsp;Madagascar is full of places still untouched by the urban frenzy. Manambato is one of these lost gems, located in the east of the Island, about 20km from Brickaville. Nestled on the shores of a freshwater lake - Lake Rasoabe - whose legends inspire the imagination, its beauty is enhanced by its proximity to the Canal des Pangalanes.
                            <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;To reach this inconspicuous little corner of paradise, you have to take a side road riddled with crevasses that will awaken the adventurous side of the traveler. The difficulty of getting to Manambato makes your stay all the more enjoyable. The water beckons for an exquisite moment of immersion. The sound of the waves and the rustling of the surrounding lush vegetation.Lake Rasoabe and the sea also allow our taste buds to savor the fresh fish and seafood caught by the fishermen of the nearby villages, and even better, the hiker, adventurer or simply nature lover that you are, you can't help but marvel at the wealth of discoveries on offer. A boat trip on the canal is also a must.
                            <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;In short, Manambato is an ideal destination for those seeking an off-the-beaten-path adventure, immersing themselves in nature, and experiencing the local Malagasy culture.</p>
                        <div className='ref'>
                            <a href='https://web.facebook.com/people/Le-sable-Blanc-Lodge/61556933793681/'><FacebookIcon sx={{ color: '#D0AC01' }} /></a>
                            <a href="https://www.instagram.com/lesableblanc89/"><InstagramIcon sx={{ color: '#D0AC01' }} /></a>
                        </div>
                    </div>
                </div>
            </Box>
            <Box>
                <div className='activities'>
                    <h2>ACTIVITIES</h2>
                    <p>Whether you're a traveler seeking a comfortable place to rest or a vacationer looking for nearby attractions, our lodge is committed to providing an unforgettable experience with its unique amenities and personalized services.</p>
                    <ImageList sx={{ width: "100%", height: 700 }} cols={cols}>
                        {itemData.map((item, idx) => (
                            <ImageListItem
                                key={item.alt}
                                onClick={() => {
                                    setActiveIndex(idx);
                                    setVisible(true);
                                }}
                                sx={{
                                    ":hover": { cursor: 'pointer' }
                                }}
                            >
                                <img
                                    srcSet={item.src}
                                    src={item.src}
                                    alt={item.alt}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    title={item.alt}
                                    position="bottom"
                                    sx={{ fontWeight: 'bold' }}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                    <Viewer
                        visible={visible}
                        onClose={() => { setVisible(false); }}
                        activeIndex={activeIndex}
                        images={itemData}
                        noImgDetails={true}
                        noFooter={true}
                        noNavbar={true}
                        disableKeyboardSupport={true}
                        defaultScale={1.2}
                    />
                </div>
            </Box>
            <Box>
                <div className='exploration'>
                    <h2>EXPLORING THE LOCAL VILLAGES</h2>
                    <p>“Le sable blanc” is a haven for those seeking escape from the ordinary. It’s a place to reconnect with nature, and create memories that will last for a lifetime</p>
                    <CarouselComponent className="carousel" />
                </div>
            </Box>
            <div className='services-1'>
                <div className="imagecontainer">
                    <img src="images/sable_blanc_85.jpg" alt="sable_blanc_lodge" className='image' />
                </div>
                <div className='card'>
                    <h3>OUR LODGE</h3>
                    <p className='description'>Rooms for relaxation</p>
                    <p>Experience the ultimate in relaxation at our lodge, where our cozy rooms offer a blend of comfort and luxury, perfect for unwinding and reconnecting with nature's beauty.</p>
                    <Link
                        href="/services"
                        underline="none"
                        variant='button'
                        sx={linkStyle}
                    >View more <NavigateNextIcon /></Link>
                </div>
            </div>
            <div className='services-2'>
                <div className='card'>
                    <h3>OUR RESTAURANT</h3>
                    <p className="description">Dining and Drinks</p>
                    <p>Discover the lodge's gastronomic haven, where our restaurant offers a refined dining experience complemented by a stylish bar, perfect for those seeking a blend of fine dining and a lively atmosphere to unwind and enjoy.</p>
                    <Link
                        href="/services"
                        variant='button'
                        underline='none'
                        sx={linkStyle}
                    >View more <NavigateNextIcon /></Link>
                </div>
                <div className="imagecontainer">
                    <img src="images/sable_blanc_72.jpg" alt="sable_blanc_lodge" className='image' />
                </div>
            </div>
            <div className='landscape'>
                <img className='landscape-image' src="logo_manambato.png" alt="le sable blanc manambato logo" />
                <div className='description-contact-container'>
                    <p className='description'>The haven for those seeking escape from the ordinary. It’s a place to reconnect with nature, and create memories that will last for a lifetime</p>
                    <Link
                        variant='button'
                        href='/contact'
                        underline='none'
                        className='button'
                        sx={{
                            display: 'flex',
                            color: '#FFFFFF',
                            border: 'solid #FFFFFF',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            width: 'max-content',
                            height: 'min-content',
                            fontSize: '12px',
                            ":hover": {
                                backgroundColor: '#fff',
                                fontWeight: 'bold',
                                color: '#D0AC01',
                            },
                            margin: 'auto'
                        }}>CONTACT US</Link>
                </div>
            </div>
        </div>
    )
}

export default Home;
import './contact.styles.scss';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { Fragment } from 'react';
import { useMediaQuery } from '@mui/material';
import SimpleNavBar from './../../components/simple-navbar/navbar.component'
import emailjs from '@emailjs/browser';

import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import CircularProgress from '@mui/material/CircularProgress';

const scaleVariant = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, x: -200, },
}

const Contact = () => {
    const isExtraLargeScreen = useMediaQuery('(min-width:1024px)');
    const isSmallScreen = useMediaQuery('(max-width:750px)');
    const size = isExtraLargeScreen ? "normal" : "small";
    const width = isExtraLargeScreen ? "45%" : (isSmallScreen ? "100%" : "55%");
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const honeypot = e.target.honeypot.value;
        if (honeypot) {
            return;
        }
        emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current)
            .then((result) => {
                window.location.reload();
            }, (error) => {
                console.log(error.text);
                console.log("error sending message, try again!");
            });
    };

    const control = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);

    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <Fragment>
            <SimpleNavBar isVisible={true} />
            {isLoading ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)' // Optional: Add a semi-transparent background
                }}>
                    <CircularProgress />
                </div> : ""}
            <div className='contact-container'>
                <motion.div
                    style={{
                        width: width,
                        padding: 0,
                        margin: 0,
                    }}
                    ref={ref}
                    variants={scaleVariant}
                    initial="hidden"
                    animate={control}
                >

                    <form ref={form} onSubmit={sendEmail}>
                        <h2 className='title'>Contact Us</h2>
                        <p className='description'>Feel free to send us a message via email, give us a call, or reach out to us on Facebook. Rest assured, we prioritize your communication and aim to respond to your queries as quickly as possible, ensuring a seamless and personalized experience.</p>
                        <input type="text" name="honeypot" style={{ display: 'none' }} />

                        <div>
                            <TextField
                                type="text"
                                name='email'
                                fullWidth
                                required
                                margin='normal'
                                label="Email"
                                variant="standard" />
                        </div>
                        <div>
                            <TextField
                                type="text"
                                name='phone'
                                fullWidth
                                required
                                margin='normal'
                                label="Phone"
                                variant="standard" />
                        </div>
                        <div>
                            <TextField
                                type="text"
                                name='name'
                                fullWidth
                                required
                                margin='normal'
                                label="Name"
                                variant="standard" />
                        </div>
                        <div>
                            <TextField
                                type="text"
                                name='message'
                                variant="filled"
                                fullWidth
                                multiline
                                required
                                label="Message"
                                margin='normal'
                                sx={{
                                    marginTop: '40px',
                                }}
                                rows={4} />
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: '40px' }}>
                            <Button type='submit' size={size} variant="contained" endIcon={<SendIcon />} color="success">
                                Send
                            </Button>
                        </div>
                    </form>
                </motion.div>
                <div className='image-container'></div>
            </div>
        </Fragment>
    )
}

export default Contact;
import styled from "styled-components";


export const InfoContainer = styled.div`
    background-color: #D0AC01;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    padding-right: 0;
`

export const LogoContainer = styled.div`
    display: block;
    padding: 0;
    margin: 0;
    width: fit-content;
    @media (min-width: 525px) {
        display: flex;
        gap: 20px;
    }
`
export const InfoText = styled.p`
    font-weight: normal;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    text-wrap: wrap;
    width: fit-content;
    text-wrap: wrap;
`
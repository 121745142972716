import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const SimpleLogoContainer = styled(Link)`
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
`

export const Logo = styled.img`
    width: 100px;
    height: auto;
`

export const SimpleNavLink = styled(Link)`
    display: inline-block;
    color: #000;
    text-decoration: none;
    font-size: 14px;
    &.active {
        font-weight: bold;
    }
    margin: 20px;
    padding: 10px;
    text-align: center;
`


export const SimpleNav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #D0AC01;
    position: fixed;
    top:0;
    left:0;
    z-index:100;
    width:100%;

    transform: translateY(-100%);
    transition: transform 0.5s ease-out;

    @keyframes slideIn {
        from {
          transform: translateY(-100%);
        }
        to {
          transform: translateY(0);
        }
     }

     @keyframes slideOut {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(-100%);
        }
     }    

     &.visible {
        animation: slideIn 0.5s forwards;
     }

     &.hidden {
        animation: slideOut 0.2s backwards;
     }

      
`
import "./App.scss";
// import Layout from "./routes/layout/layout.component";
import Contact from "./routes/contact/contact.component";
import Home from "./routes/home/home.component";
import Services from "./routes/services/services.component";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./routes/layout/layout.component";
import Footer from "./components/footer/footer.component";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/services" element={<Services id={0}/>} />
        </Route>
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;

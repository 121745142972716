import React, { useState, useEffect } from 'react';
import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import ComplexNavBar from "../../components/complex-navbar/navbar.component";
import SimpleNavBar from "../../components/simple-navbar/navbar.component";
import InfoBar from "./../../components/infobar/infobar.component"

const Layout = () => {
    const [showSimpleNavbar, setShowSimpleNavbar] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (currentScrollPos > window.innerHeight / 2) {
                setShowSimpleNavbar(true);
            } else {
                setShowSimpleNavbar(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Fragment>
            <InfoBar />
            <ComplexNavBar />
            <SimpleNavBar isVisible={showSimpleNavbar} />
            <Outlet />
        </Fragment>
    )
}

export default Layout;
import './footer.styles.scss';
import { Link } from '@mui/material';
import {
    Place,
    Email,
    ContactPhone,
    Facebook,
    Instagram
} from '@mui/icons-material';

const Footer = () => {
    return (
        <div className="footer-container">
            <div className='information'>
                <div className='information-element'>
                    <Link
                        underline='none'
                        color={'#fff'}
                        display={'block'}
                        marginBottom={'40px'}
                        sx={{ ':hover': { color: '#D0AC01' } }}
                        href="/"
                    >Home</Link>
                    <Link
                        underline='none'
                        color={'#fff'}
                        display={'block'}
                        marginBottom={'40px'}
                        sx={{ ':hover': { color: '#D0AC01' } }}
                        href="/services"
                    >Services</Link>
                    <Link
                        href="/contact"
                        underline='none'
                        color={'#fff'}
                        display={'block'}
                        marginBottom={'40px'}
                        sx={{ ':hover': { color: '#D0AC01' } }}
                    >Contact Us</Link>
                </div>
                <div className='information-element'>
                    <div>
                        <span
                            style={{
                                display: 'flex',
                                paddingBottom: '40px',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                                fontWeight: 'normal'
                            }}><Place fontSize='14px' sx={{ color: '#D0AC01' }} /> Address:</span>
                        <span style={{ display: 'block', paddingBottom: '40px' }}>Le Sable Blanc</span>
                        <span style={{ display: 'block', paddingBottom: '40px' }}>ANTANDROKOMBY</span>
                        <span style={{ display: 'block', paddingBottom: '40px' }}>Manambato-Madagascar</span>
                    </div>
                </div>
                <div className='information-element'>
                    <div>
                        <span
                            style={{
                                display: 'flex',
                                paddingBottom: '40px',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                                fontWeight: 'normal'
                            }}><ContactPhone fontSize='14px' sx={{ color: '#D0AC01' }} /> Contact:</span>
                        <span style={{ display: 'block', paddingBottom: '40px' }}>+261 38 24 012 98</span>
                        <span style={{ display: 'block', paddingBottom: '40px' }}>+261 38 23 017 13</span>
                        <span style={{ display: 'block', paddingBottom: '40px' }}>+261 34 36 968 89</span>
                        <span style={{ display: 'block', paddingBottom: '40px' }}>+261 38 60 076 98</span>
                    </div>
                </div>
                <div className='information-element'>
                    <div>
                        <span
                            style={{
                                display: 'flex',
                                paddingBottom: '40px',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '10px',
                                fontWeight: 'normal',
                            }}><Email fontSize='14px' sx={{ color: '#D0AC01' }} /> Email:</span>
                        <a href="mailto:lesableblancantandrokomby@gmail.com" style={{ textDecoration: 'none', color: 'white', fontWeight: 'normal' }}><span style={{ display: 'block' }}>
                            lesableblancantandrokomby<br />@gmail.com
                        </span></a>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '90%',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    gap: '40px',
                    borderBottom: 'solid rgba(255,255,255,0.1) 0.1px',
                    paddingBottom: '10px',
                }}>
                <Link
                    href='https://web.facebook.com/people/Le-sable-Blanc-Lodge/61556933793681/'
                    target="_blank"
                >
                    <Facebook sx={{ fontSize: '25px', color: '#fff', ':hover': { color: '#D0AC01' } }} />
                </Link>
                <Link
                    href='https://www.instagram.com/lesableblanc89/'
                    target="_blank"
                >
                    <Instagram sx={{ fontSize: '25px', color: '#fff', ':hover': { color: '#D0AC01' } }} />
                </Link>
            </div>
            <p style={{ fontSize: '10px', fontWeight: 'lighter', textAlign: 'center' }}>©2024 - All rights reserved - fiononana.andriatsalama@gmail.com</p>
        </div>
    )
}

export default Footer;
import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import './carousel.styles.scss'

const images = [
    '/images/carousel/sable_blanc_20.jpg',
    '/images/carousel/sable_blanc_13.jpg',
    '/images/carousel/sable_blanc_21.jpg',
    '/images/carousel/sable_blanc_22.jpg',
    '/images/carousel/sable_blanc_23.jpg',
    '/images/carousel/sable_blanc_24.jpg',
    '/images/carousel/sable_blanc_25.jpg',
    '/images/carousel/sable_blanc_119.jpg',
    '/images/carousel/sable_blanc_92.jpg',
    '/images/carousel/sable_blanc_109.jpg',
    '/images/carousel/sable_blanc_130.jpg',
    '/images/carousel/sable_blanc_124.jpg',
];

const CarouselComponent = () => {
    return (
        <Carousel
            className='carousel-container'
            interval={3000}
            stopAutoPlayOnHover={false}
            navButtonsAlwaysVisible={true}
        >
            {
                images.map((item, i) => <Item key={i} item={item} />)
            }
        </Carousel>
    )
};
function Item(props) {
    return (
        <Paper className='paper'>
            <img src={props.item} alt="" className='carousel-image' />
        </Paper>
    )
}
export default CarouselComponent;
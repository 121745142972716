import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import BungalowIcon from '@mui/icons-material/Bungalow';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { AntTabs, AntTab } from './services.elements';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ServicesElement from '../../components/services/services.component';

import './services.styles.scss';

const lodgeImages = [
    { src: '/images/lodge/sable_blanc_39.jpg' },
    { src: '/images/lodge/sable_blanc_48.jpg' },
    { src: '/images/lodge/sable_blanc_82.jpg' },
    { src: '/images/lodge/sable_blanc_80.jpg' },
    { src: '/images/lodge/sable_blanc_43.jpg' },
    { src: '/images/lodge/sable_blanc_44.jpg' },
    { src: '/images/lodge/sable_blanc_116.jpg' },
    { src: '/images/lodge/sable_blanc_87.jpg' },
];

const restaurantImages = [
    { src: '/images/restaurant/sable_blanc_72.jpg' },
    { src: '/images/restaurant/sable_blanc_75.jpg' },
    { src: '/images/restaurant/sable_blanc_53.jpg' },
    { src: '/images/restaurant/sable_blanc_54.jpg' },
    { src: '/images/restaurant/sable_blanc_76.jpg' },
    { src: '/images/restaurant/sable_blanc_88.jpg' },
];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            style={{ width: '100%' }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0, width: '100%' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Services = ({ id }) => {

    const theme = useTheme();
    const [value, setValue] = React.useState(id);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="services-container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '40px', marginTop: '20px' }}>
                <AntTabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                >
                    <AntTab icon={<BungalowIcon />} iconPosition="start" label="Lodge" {...a11yProps(0)} />
                    <AntTab icon={<BrunchDiningIcon />} iconPosition="start" label="Restaurant" {...a11yProps(1)} />
                </AntTabs>
            </Box>

            <CustomTabPanel value={value} index={0} dir={theme.direction}>
                <ServicesElement
                    title="OUR LODGE"
                    subtitle="Rooms for relaxation"
                    desc="Experience the ultimate in relaxation at our lodge, where our cozy rooms offer a blend of comfort and luxury, perfect for unwinding and reconnecting with nature's beauty."
                    imgdesc="/images/sable_blanc_85.jpg"
                    images={lodgeImages}
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1} dir={theme.direction}>
                <ServicesElement
                    title="OUR RESTAURANT"
                    subtitle="Dining and Drinks"
                    desc="Discover the lodge's gastronomic haven, where our restaurant offers a refined dining experience complemented by a stylish bar, perfect for those seeking a blend of fine dining and a lively atmosphere to unwind and enjoy."
                    imgdesc="/images/restaurant/sable_blanc_55.jpg"
                    images={restaurantImages}
                />
            </CustomTabPanel>
        </div>
    )
}

export default Services;
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import React, { useEffect } from 'react';

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Viewer from 'react-viewer';

const scaleVariant = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, x: 200 },
}

const ServicesElement = ({ imgdesc, title, subtitle, desc, images }) => {
    const [visible, setVisible] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(0);

    const control = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);


    return (
        <motion.div
            ref={ref}
            variants={scaleVariant}
            initial="hidden"
            animate={control}
        >
            <div
                className='services-description'
            >
                <div className="services-image-container">
                    <img src={imgdesc} alt="" />
                </div>
                <div className="services-text-description">
                    <h2>{title}</h2>
                    <p style={{ color: "#000" }}>{subtitle}</p>
                    <p style={{ fontWeight: "normal" }}>{desc}</p>
                </div>
            </div>
            <div className='services-photo'>
                <h2>PHOTOS</h2>
                <ImageList
                    sx={{
                        width: '100%',
                        height: 'max-content',
                        marginTop: '40px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }}
                >
                    {images.map((img, idx) => (
                        <ImageListItem key={idx} sx={{ overflow: 'hidden' }} >
                            <img
                                srcSet={img['src']}
                                src={img['src']}
                                alt={`sable-blanc-manambato-${idx}`}
                                loading="eager"
                                onClick={() => {
                                    setActiveIndex(idx);
                                    setVisible(true);
                                }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
                <Viewer
                    visible={visible}
                    onClose={() => { setVisible(false); }}
                    activeIndex={activeIndex}
                    images={images}
                    noImgDetails={true}
                    downloadable={false}
                    defaultScale={1.2}
                />
            </div>
        </motion.div>
    )
}

export default ServicesElement;
import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const NavLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    height: max-content;
    min-width: 76px;
    text-align: center;
    @media (max-width:  325px) {
        font-size: 12px;
    }
    .background, .cache, .text, .imagefooter, .playbutton {
        display: none;
    }
    &.active {
        color: #D0AC01;
        font-weight: bold;
        .background, .cache, .playbutton {
            display: block;
        }
        .text {
            display: flex;
        }
        .imagefooter {
            display: flex;
            z-index: 0;
        }
    }
    &:hover {
        font-weight: bold;
        .background {
            display: block;
            z-index:-98;
        }
        .cache {
            display: block;
            z-index:-97;
        }
        .text {
            z-index: -97;
            display: flex;
        }
    }
`

export const LogoContainer = styled(Link)`
    background-color: #fff;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    padding: 10px;
`
export const Logo = styled.img`
    width: 200px;
    height: auto;
    @media (max-width: 850px) {
        width: 100px;
    }
`
export const NavMenu = styled.div`
    display: flex;
    gap: 80px;
    padding-top: 20px;
    height: fit-content;
    margin-right: 80px;
    @media (max-width: 700px) {
        border-bottom: solid #fff 0.5px;
        align-content: center;
        justify-content: space-around;
        gap: 20px;
        width: 80%;
        margin: 0;
        padding-bottom: 20px;
        @media (max-width: 325px) {
            width: 100%;
            gap: 0;
        }
    }
`            
export const Nav = styled.nav`
    display: flex;
    height: 100vh;
    justify-content: space-between;
    padding-left: 40px;
    padding-top: 20px;
    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 40px;
        padding-left: 0;
    }
`

export const VideoFooterContainer = styled.div`
    position: absolute;
    right: 40px;
    left: 40px;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const ImageBackground = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: -100;
    background-size: cover;
    object-fit: cover;

    @keyframes fadeThrough {
        0% {
          opacity: 0;
       }
        90% {
          opacity: 0.8;
       }
        100% {
          opacity: 1;
       }
      }

      animation: fadeThrough 500ms linear;
`

export const Cache = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    z-index: -99;
    display: flex;
    justify-content: center;
`

export const Text = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items:center;
    z-index: -99;

    font-weight: bold;
    font-style: normal;
    font-size: 40px;
    opacity: 0.5;
    color: #fff;

    @media (max-width: 850px) {
        opacity: 0;
    }
`

export const ImageFooter = styled.span`
    color: #fff;
    font-weight: normal;
    font-size: 30px;
    position: absolute;
    right: 40px;
    bottom: 40px;
    @media (max-width: 545px) {
        right: 20px;
        bottom: 20px;
        font-size: 18px;
    }
`

export const IconButtonContainer = styled.span`
    position: absolute;
    left: 40px;
    bottom: 35px;
    @media (max-width: 545px) {
        left: 20px;
        bottom: 10px;
    }
`
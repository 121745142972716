import React, { useRef, useState } from 'react';

import {
    NavLink,
    NavMenu,
    Nav,
    LogoContainer,
    ImageBackground,
    Cache,
    Text,
    ImageFooter,
    IconButtonContainer,
    Logo
} from "./navigation.styles";

import {
    PlayCircleFilled,
    PauseCircleFilled,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import video from './../../assets/videos/video.mp4';

const ComplexNavBar = () => {
    const videoRef = useRef();
    const [isPlaying, setIsPlaying] = useState(true);
    const [displayVideo, setDisplayVideo] = useState(true);

    const togglePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <Nav>
            <LogoContainer
                to="/"
                onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setDisplayVideo(true);
                }}
            ><Logo src="logo_manambato.png" alt="le sable blanc lodge manambato logo" /></LogoContainer>
            <NavMenu>
                <NavLink
                    to="/"
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        setDisplayVideo(true);
                    }}
                >
                    HOME
                    <ImageBackground as="video" ref={videoRef} autoPlay loop muted className='background' src={video} type="video/mp4">
                    </ImageBackground>
                    <Cache className='cache' />

                    <IconButtonContainer>
                        {displayVideo ? <IconButton
                            className='playbutton'
                            onClick={togglePlayPause}
                            aria-pressed="false"
                            sx={{
                                color: '#fff',
                                padding: 0,
                                width: 'fit-content',
                                height: 'fit-content',
                            }}>
                            {isPlaying ? <PauseCircleFilled sx={{
                                fontSize: 30, ":hover": {
                                    color: '#D0AC01',
                                },
                            }} /> : <PlayCircleFilled sx={{
                                fontSize: 30, ":hover": {
                                    color: '#D0AC01',
                                }
                            }} />}
                        </IconButton> : ""}
                    </IconButtonContainer>
                </NavLink>
                <NavLink
                    to="/services"
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        setDisplayVideo(false);
                    }}
                >
                    SERVICES
                    <ImageBackground className='background' style={{ backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, .3),  rgba(0, 0, 0, .3)), url("/images/sable_blanc_37.jpg")' }} />
                    <Text className="text"> OUR SERVICES </Text>
                </NavLink>
                <NavLink
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        setDisplayVideo(false);
                    }}
                    to="/contact"
                >
                    CONTACT US
                    <ImageBackground className='background' style={{ backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, .2),  rgba(0, 0, 0, .2)), url("/images/sable_blanc_12.jpg")', backgroundPosition: 'center' }} />
                    <Text className="text"> CONTACT US </Text>
                </NavLink>
                <ImageFooter>Manambato - MADAGASCAR</ImageFooter>
            </NavMenu>
        </Nav >
    )
}

export default ComplexNavBar;